







import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class IconIndicator extends Vue {
  @Prop() public value!: number;
}
